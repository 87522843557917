import React from "react"
import Layout from "../components/layout"
import { Card } from "react-bootstrap"
import { Helmet } from "react-helmet"

const film = () => {
  return (
    <Layout>
      <Helmet>
        <title>Film - Pamela Mary Brown</title>
      </Helmet>
      <h2>Film</h2>

      <Card style={{ border: "none", backgroundColor: "transparent" }}>
        <Card.Title style={{ marginTop: "10px" }}>The Fag (2009)</Card.Title>
        <div className="video-style">
          <iframe
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/oPHy8IYJG54/?showinfo=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <Card.Text>
          Winner of the Scanbitz Short Film Challenge, Clones Film Festival,
          receiving the Audience Award and Francie Trophy (named after ‘Francie
          Brady’, author Pat McCabe’s protagonist in The Butchers Boy).
        </Card.Text>
        <Card.Text>
          The challenge was to cast, rehearse, scout locations, shoot and edit a
          short film in 48 hours.
        </Card.Text>

        <Card.Text>
          For more information visit
          <a
            href="https://clonesfilmfestival.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            clonesfilmfestival.com
          </a>
        </Card.Text>
      </Card>
    </Layout>
  )
}

export default film
